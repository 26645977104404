import React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import TextareaAutosize from "react-autosize-textarea"

class ContactFrorm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
      lang: this.props.lang,
      formSubmission: {
        subject: "",
        email: "",
        message: "",
      },
    }
    this.ContactForm = React.createRef()
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  render() {
    const lang = this.state.lang
    // Form info
    const formInfo = this.state.content
    const formTitle = formInfo.form_title
    // Form labels
    const formStrings = new Map()
    formInfo.form_strings.forEach(el =>
      formStrings.set(el.original, el.translation)
    )

    // Transform topics to array of objects (for react-select)

    class Topic {
      constructor(value, label) {
        this.value = value
        this.label = label
      }
    }
    const formTopics = []
    formInfo.topics.forEach(el =>
      formTopics.push(new Topic(el.topic, el.topic))
    )

    // Messages

    const errorMessages = {
      invalidEmailFormat: {
        en: "Invalid email format",
        de: "Ungültiges E-Mail-Format",
        it: "Formato e-mail non valido",
        fr: "Format de courriel invalide",
      },
      messageSendingFailed: {
        en: "Message sending failed, please contact us via email",
        de:
          "Nachrichtenversand fehlgeschlagen! Bitte, kontaktieren Sie uns per E-Mail",
        it:
          "L'invio del messaggio non è riuscito! Per favore, contattaci via e-mail",
        fr: "L'envoi de messages a échoué ! Veuillez nous contacter par e-mail",
      },
    }

    const successMessage = {
      en: "Thank you for your message!",
      de: "Vielen Dank für Ihre Nachricht!",
      it: "Grazie per il tuo messaggio!",
      fr: "Merci pour votre message!",
    }

    return (
      <div className="form-container appear">
        {formTitle && (
          <div className="form__title">
            <h2>{formTitle}</h2>
          </div>
        )}
        <Formik
          initialValues={{
            email: "",
            subject: "",
            message: "",
          }}
          validate={values => {
            const errors = {}
            // Email
            if (!values.email) {
              errors.email = true
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = errorMessages.invalidEmailFormat[lang]
            }
            // Message
            if (!values.message) {
              errors.message = true
            }

            return errors
          }}
          onSubmit={(values, { setStatus, resetForm }) => {
            const form = this.ContactForm.current
            const url =
              typeof window !== "undefined" ? window.location.href : ""

            fetch(url, {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: this.encode({
                "form-name": form.getAttribute("name"),
                ...values,
              }),
            })
              .then(() => {
                resetForm()
                setStatus({
                  success: true,
                })
              })
              .catch(error => alert(error))
          }}
        >
          {({ errors, status, touched, isValid }) => {
            return (
              <Form
                id="contact-form"
                method="POST"
                name="contact-form"
                className="js-scroll-target"
                data-netlify="true"
                ref={this.ContactForm}
                noValidate
              >
                <input type="hidden" name="contact-form" value="Contact Form" />
                <div className="form__group">
                  <label htmlFor="email">{formStrings.get("your-email")}</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder={formStrings.get("your-email")}
                    className={errors.email && touched.email ? "invalid" : ""}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="form-message form-message--error"
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="subject">{formStrings.get("subject")}</label>
                  <Field
                    type="text"
                    name="subject"
                    placeholder={formStrings.get("subject")}
                  />
                </div>
                <div className="form__group form__group--full">
                  <label htmlFor="message">{formStrings.get("message")}</label>
                  <Field
                    name="message"
                    component="textarea"
                    className={
                      errors.message && touched.message ? "invalid" : ""
                    }
                    placeholder={formStrings.get("message")}
                  ></Field>
                </div>
                <div className="form__group form__group--submit">
                  <div className="form-message form-message--success">
                    {isValid &&
                      status !== undefined &&
                      status.success === true && (
                        <span>{successMessage[lang]}</span>
                      )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn--oval btn--oval--blue btn--oval--small "
                  >
                    {formStrings.get("send")}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
        {/*
        // Unused select for subjects, left just in case for a bit
        <Select
        options={formTopics}
        value={this.state.selectPlaceholder}
        className="select"
        classNamePrefix="select"
        isSearchable={false}
        onChange={selectedOption => {
          this.setState({
            selectPlaceholder: {
              label: selectedOption.label,
              key: selectedOption.value,
            },
          })
          setFieldValue("topic", selectedOption.value, false)
        }}
      />
      <input type="hidden" name="topic" value="" /> */}
      </div>
    )
  }
}

export default ContactFrorm
