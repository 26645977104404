import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import * as Buttons from "../components/Buttons"
import ContactList from "../components/ContactList"
import { Transition } from "react-transition-group"
import gsap from "gsap"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"
import Lottie from "react-lottie"
import MapMarker from "../visuals/contact-map.json"
import { isMobile } from "react-device-detect"
import Div100vh from "react-div-100vh"
import ContactForm from "../components/ContactForm"


class Contacts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapVisible: false,
      data: this.props.data,
      pageContext: this.props.pageContext,
      formSubmission: {
        topic: "Partnership",
        email: "karolis@vsbl.lt",
        message: "Lets work",
      },
    }
  }

  componentDidMount() {
    this.setState({
      mapVisible: true,
    })

    // Prevent scroll untill map appear animation is finished

    this.setupAppearAnimations()

    if (!isMobile) {
      this.toggleScroll(1500)
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState({
      mapVisible: false,
    })
  }

  setupAppearAnimations() {
    const controller = new ScrollMagic.Controller()

    for (let i = 1; i < 6; i++) {
      appearAnimation(`.appear--${i}`, 0.1 * i + 1)
    }

    new ScrollMagic.Scene({
      triggerElement: document.querySelector(".form-container"),
      triggerHook: 1,
    })
      .on("enter", () => {
        appearAnimation(document.querySelector(".form-container"))
      })
      .addTo(controller)

    new ScrollMagic.Scene({
      triggerElement: document.querySelector(".intro__contacts-container"),
      triggerHook: 0.3,
    })
      .on("enter leave", event => {
        gsap.to("g[clip-path='url(#__lottie_element_4)']", 0.3, {
          opacity: event.type === "enter" ? 0 : 1,
        })
      })
      .addTo(controller)

    window.addEventListener("resize", () => {
      gsap.to("g[clip-path='url(#__lottie_element_2)']", 0, {
        y: 0,
      })
    })
  }

  toggleScroll(delay) {
    const scrollContainer = document.querySelector("#scroll-container")

    scrollContainer.style.pointerEvents = "none"

    setTimeout(() => {
      scrollContainer.style.pointerEvents = "initial"
    }, delay)
  }

  render() {
    const data = this.state.data
    const pageContext = this.state.pageContext

    const mapVisible = this.state.mapVisible

    // Contact list

    const contactListIndex = data.contactList.edges.findIndex(
      el =>
        el.node.template === "TemplateHome.php" &&
        el.node.polylang_current_lang === pageContext.langWP
    )
    const contactList =
      data.contactList.edges[contactListIndex].node.acf.contact_info
        .contact_list

    // Intro info
    const intro = data.contactPageInfo.acf.intro_contacts

    // Marker animation option

    const markerAnimOptions = {
      loop: false,
      autoplay: true,
      animationData: MapMarker,
      assetsPath: "../../",
    }

    return (
      <Layout
        headerModifier="header--dark"
        lang={pageContext.lang}
        tpl={pageContext.templateGT}
        theme="white"
        intro={false}
      >
        <Transition
          timeout={isMobile ? 500 : 1000}
          mountOnEnter
          unmountOnExit
          in={mapVisible}
          onEnter={(node, done) => {
            const appearDuration = 2
            const delay = 0.3

            gsap.to("#contacts-bg", 0, {
              rotateX: 0,
              scale: 1.8,
              opacity: 0,
            })

            gsap.to("#contacts-bg", appearDuration, {
              rotateX: 40,
              scale: 1,
              y: 0,
              z: 0,
              opacity: 1,
              ease: "Power2.easeOut",
              delay: delay,
            })
            gsap.to(".contacts-bg-container", 2, {
              y: 0,
              ease: "Power1.easeOut",
              rotateX: 0,
              delay: delay,
            })
          }}
        >
          <div className="contacts-bg-container">
            <div id="contacts-bg">
              {!isMobile && (
                <Lottie
                  className="contacts-bg-marker"
                  options={markerAnimOptions}
                  isClickToPauseDisabled={true}
                />
              )}
            </div>
          </div>
        </Transition>

        <Div100vh id="intro" className="intro--contacts">
          <div className="grid">
            <div className="row">
              <div className="col lg-8-12 lg-push-left-2-12">
                <div className="intro__content">
                  <div className="text-block">
                    <h1
                      className="appear appear--1"
                      dangerouslySetInnerHTML={{ __html: intro.heading }}
                    />
                    {intro.subheading && (
                      <p
                        className="appear appear--2"
                        dangerouslySetInnerHTML={{ __html: intro.subheading }}
                      />
                    )}
                    {intro.button.title && intro.button.url && (
                      <div className="text-block__btn appear appear--3">
                        {Buttons.primary(
                          "blue btn--oval--small",
                          intro.button.url,
                          intro.button.title,
                          true,
                          intro.button.target
                        )}
                      </div>
                    )}
                  </div>
                  <div className="intro__contacts-container">
                    <div className="intro__contacts appear appear--4">
                      <div className="intro__contacts__hours">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: intro.office_hours,
                          }}
                        />
                      </div>
                      <div className="intro__contacts__list">
                        {intro.contact_column_title && (
                          <small>{intro.contact_column_title}</small>
                        )}
                        <ContactList
                          modifier="contact-list--small"
                          data={contactList}
                        />
                      </div>
                    </div>
                  </div>
                  {intro.scroll_button_title && (
                    <button
                      className="intro__scroll btn btn--arrow btn--arrow--down js-scroll-down appear appear--5 sm-hide"
                      // onClick={Int.scrollDown}
                      // role="button"
                      // onKeyDown={Int.scrollDown}
                      // tabIndex={0}
                    >
                      {intro.scroll_button_title}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Div100vh>

        <section id="form">
          <div className="grid">
            <div className="row">
              <div className="col lg-8-12 lg-push-left-2-12">
                <ContactForm
                  content={data.contactPageInfo.acf.form}
                  lang={pageContext.lang}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        contactPageInfo: wordpressPage(wordpress_id: { eq: $id }) {
          acf {
            intro_contacts {
              heading
              subheading
              button {
                title
                url
                target
              }
              office_hours
              contact_column_title
              scroll_button_title
              background_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                      src
                    }
                  }
                }
              }
            }
            form {
              form_title
              form_strings {
                original
                translation
              }
              topics {
                topic
              }
            }
          }
        }

        contactList: allWordpressPage {
          edges {
            node {
              template
              polylang_current_lang
              acf {
                contact_info {
                  contact_list {
                    label
                    type
                    email
                    phone
                  }
                  btn {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <Contacts data={data} context={pageContext} {...props} />
    )}
  />
)
